import React, { useEffect, useState } from 'react';
import AnimateHeight from "react-animate-height";
import { useLocation } from "@gatsbyjs/reach-router"
import { FormattedMessage, useIntl } from 'react-intl';

import { openPositions } from '../../../constants/constants';

import './PositionsSection.scss';
import { defaultFormattedMessageValues } from '../../../constants/general';
import { createId } from '../../../services/utils';

const PositionsSection = () => {
    const [openedPositions, setOpenedPositions] = useState(new Array(openPositions.length).fill(false));
    const location = useLocation();
    const [preventHrefLinking, setPreventHrefLinking] = useState(false);

    const handleOpenClick = (i) => {
        openedPositions[i] = !openedPositions[i];
        setOpenedPositions([...openedPositions]);
    }

    const { messages } = useIntl();

    useEffect(() => {
        if (location.hash && !preventHrefLinking) {
            const index = openPositions.findIndex(a => `#${createId(a.name)}` === location.hash);
            if (index !== -1) {
                const positions = [...openedPositions];
                if (!positions[index]) {
                    positions[index] = true;
                    setOpenedPositions(positions);
                }
            }
            setPreventHrefLinking(true);
        }
    }, [location.hash]);

    return (
        <>
            <div className="positions-section content center padding">
                <div className="position-lower-section">
                    <div className="position-search">
                        <h5>
                            <FormattedMessage
                                id='looking_for_title'
                            />:
                        </h5>
                        {openPositions.map(({name, titles, tags, desc, info}, i) => (
                            <div className="single-position-wrapper" key={name}>
                                <a id={createId(name)} href={`#${createId(name)}`} className={`position ${openedPositions[i] && 'opened'}`} onClick={() => handleOpenClick(i)}>
                                    <div className="position-titles">
                                        <h3 className="position-title">{name}</h3>
                                        <div className="position-subtitles">
                                            {titles.map((title, i) => (
                                                <h6 className="position-subtitle" key={`${i}${title}`}>{title}{i !== (titles.length - 1)  && ', '}</h6>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="tags">
                                        {tags.map((tag, i) => (
                                            <div className="position-tag" key={`${i}${tag}`}>
                                                {tag}
                                            </div>
                                        ))}
                                    </div>
                                    <div
                                        className={`caret ${openedPositions[i] && 'opened'}`}
                                    >
                                        <img
                                            className="caret-image"
                                            src="/caret.svg"
                                            alt="caret"
                                            width={55}
                                            height={27.5}
                                        />
                                    </div>
                                </a>
                                <AnimateHeight duration={300} height={openedPositions[i] ? 'auto' : 0}>
                                    <div className={`position-description`} style={{ '--items': Object.entries(info).length }}>
                                        <div>
                                            <p>
                                                <FormattedMessage
                                                    id={desc}
                                                    values={{ ...defaultFormattedMessageValues, h4: chunks => (<h4 className="position-description-title">{chunks}</h4>) }}
                                                />
                                            </p>
                                        </div>
                                        {Object.entries(info).map(([key, list], i) => (
                                          <>
                                              {messages[key] && messages[key] !== '' && (
                                                <ul key={`${i}${key}`}>
                                                    <h4 className="position-description-title">
                                                        <FormattedMessage id={key} />
                                                    </h4>
                                                    {list.map((item, i) => (
                                                      <>
                                                          {messages[item] && messages[item] !== '' && (
                                                            <>
                                                              <li className="position-description-item" key={`${i}${item}`}>
                                                                <FormattedMessage id={item}/>
                                                              </li>
                                                              <br />
                                                            </>
                                                          )}
                                                      </>
                                                    ))}
                                                </ul>
                                              )}
                                          </>
                                        ))}
                                        <p className="position-cta">
                                            <FormattedMessage id="after_job_posting_cta" />
                                        </p>
                                    </div>
                                </AnimateHeight>
                            </div>
                        ))}

                        <div className="contact-us-for-position">
                            <p>
                                <FormattedMessage
                                    id="open_full_time"
                                />
                            </p>
                            <p>
                                <FormattedMessage
                                    id="contact_at"
                                />
                                <span>
                                    <FormattedMessage
                                        id="mail"
                                    />
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PositionsSection;