import React from "react";
import { FormattedMessage } from "react-intl";
import { selectionSteps } from "../../../constants/constants";
import "./CareersInfoSection.scss";
import { useWindowResize } from "../../../services/hooks";

const CareersInfoSection = () => {
    const { windowWidth } = useWindowResize();
    return (
        <div className="careers-info-wrapper padding center content header-margin">
            <h1 className="passionate-title title">
                <FormattedMessage
                    id="join1"
                />
                <br />
                <FormattedMessage
                    id="join2"
                />
            </h1>
            <div className="special-section">
                <h3 className="subsection-title">
                    <FormattedMessage
                        id="what_makes_decenter_special"
                    />
                </h3>
                <div className="subsection-description">
                    <div className="subsection-description__section">
                        <h4 className="careers-subheader">
                            <FormattedMessage
                                id="we_care_world"
                            />
                        </h4>
                        <div className="careers-info-label">
                            <FormattedMessage
                                id="we_care_world_desc"
                            />
                        </div>
                    </div>
                    <div className="subsection-description__section">
                        <h4 className="careers-subheader">
                            <FormattedMessage
                                id="we_care_about_you"
                            />
                        </h4>
                        <div>
                            <div className="careers-info-label">
                                <FormattedMessage
                                    id="we_care_about_you_desc1"
                                />
                            </div>
                            {windowWidth > 910 && (
                                <>
                                    <br/>
                                    <br/>
                                </>
                            )}
                            <div className="careers-info-label">
                                <FormattedMessage
                                    id="benefits"
                                />
                            </div>
                            <ul>
                                {
                                    [...Array(12).keys()]
                                        .map((a, i) => (
                                            <li className="careers-info-label" key={i}>
                                                <FormattedMessage
                                                    id={`benefit${i+1}`}
                                                />
                                            </li>
                                        ))
                                }
                            </ul>
                            {windowWidth > 910 && (
                                <>
                                    <br/>
                                    <br/>
                                </>
                            )}
                            <div className="careers-info-label">
                                <FormattedMessage
                                    id="inspiration1"
                                />
                            </div>
                            {windowWidth > 910 && (
                                <br/>
                            )}
                            <div className="careers-info-label">
                                <FormattedMessage
                                    id="inspiration2"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="selection-process">
                <h3 className="subsection-title">
                    <FormattedMessage
                        id="selection_process"
                    />
                </h3>
                <div className="selection-process-illustration">
                    <div className="illustration-big-screen">
                            <div className="illustration-icons">
                                {
                                    selectionSteps.map(step => (
                                        <img
                                            src={step.img}
                                            alt={step.alt}
                                            className="step-icon"
                                            key={step.name}
                                        />
                                    ))
                                }
                            </div>
                            <div className="illustration-circles">
                            {
                                selectionSteps.map((step, i) => {
                                    const isLast = i === selectionSteps.length - 1;
                                    const isFirst = i === 0;
                                    return (
                                        <div key={`${i}${step.name}`}>
                                            {
                                                !isFirst && <hr/>
                                            }
                                            <img
                                                src={"/circleIcon.svg"}
                                                alt="Circle"
                                                className="circle"
                                            />
                                            {
                                                !isLast && <hr/>
                                            }
                                        </div>
                                    )
                                })
                            }
                            </div>
                            <div className="illustration-labels">
                            {
                                selectionSteps.map((step, i) => (
                                    <div key={`${i}${step.name}`}>
                                        <div className="process-step-number">
                                            <FormattedMessage
                                                id={step.step_number}
                                            />
                                        </div>
                                        <h4>
                                            <FormattedMessage
                                                id={step.name}
                                            />
                                        </h4>
                                    </div>
                                ))
                            }
                            </div>
                    </div>
                    <div className="illustration-mobile">
                    {
                        selectionSteps.map((step, i) => (
                            <div key={`${i}${step.name}`}>
                                <div className="process-step-number">
                                    {`${i + 1}.`}
                                </div>
                                <h4>
                                    <FormattedMessage
                                        id={step.name}
                                    />
                                </h4>
                                <hr />
                            </div>
                        ))
                    }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CareersInfoSection;