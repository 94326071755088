import React from 'react';
import SimpleLocalize from "../components/SimpleLocalize/SimpleLocalize";
import MainLayout from "../components/Layouts/MainLayout/MainLayout";
import PositionsSection from "../components/Sections/PositionsSection/PositionsSection";
import CareersInfoSection from "../components/Sections/CareersInfoSection/CareersInfoSection";


const CareersPage = (props) => {
    return (
        <>
            <SimpleLocalize {...props}>
                <MainLayout
                    title={props.pageContext.messages['careers']}
                    currentLang={props.pageContext.language}
                    description={props.pageContext.messages['careers_desc']}
                    locationProps={props.location}
                    navigate={props.navigate}
                >
                    <CareersInfoSection />
                    <PositionsSection />
                </MainLayout>
            </SimpleLocalize>
        </>
    );
}

export default CareersPage;
